import React, { Fragment, useEffect, useState } from 'react'
import inspectionQueries from '../../queries/inspectionQueries'
import { useDispatch, useSelector } from 'react-redux'
import { CalendarRange, Eye, MapPin, UserRound, File, PlusCircle } from 'lucide-react'
import { IconHistory, IconUser } from '@tabler/icons-react'
import moment from 'moment'
import BreadCrumbs from '../../components/BreadCrumbs'
import { RouteConstant } from '../../routes/RouteConstant'
import { setInspection, setIsUserWise, setUser } from '../../redux/reducers/masterReducers'
import { useNavigate } from 'react-router-dom'
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers'
import Loader from '../../components/Loader'
import toast from 'react-hot-toast'
import ProgressBar from '../../components/ProgressBar'
import { useApi } from '../../components/ApiProvider'
import PdfGenerate from './PdfGenerator'
import { setInspectionData } from '../../redux/reducers/inspectionReducers'
import LoaderSmall from '../../components/LoaderSmall'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material'
import DrawerComponent from '../../components/DrawerComponent'
import { Button } from '@nextui-org/react'
import Empty from '../../components/Empty'
import ReportModal from './ReportModal'

const breadcrumb = [
  {
    label: "Vessel",
    path: RouteConstant.VESSEL,
    roles: ["SADMIN"]
  },
  {
    label: "Vessel Details",
    roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
    path: RouteConstant.VESSELDETAILS
  },
  {
    label: "Inspection History",
  }
]

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13
  },
});

const inputFields = [
  {
      name: "due_date",
      label: "Due Date",
      type: "date",
      required: true,
      grid: 12
  }
]



const InspectionHistory = () => {

  const master = useSelector((state)=> state.master)
  const vessel = useSelector((state)=> state.master.vessel)
  const loader = useSelector((state)=> state.options.loader)
  const user = useSelector((state)=> state.auth.user)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {apiData, setApiDataAndNotify} = useApi()

  useEffect(() => {
    dispatch(setLoader(true))
    dispatch(setHeaderText(`Vessel : ${master?.header}`))
  }, []);

  const [result, setResult] = useState([])

  const inspectionHistory = inspectionQueries.useInspectionHistoryMutationList(
    (res)=>{
      dispatch(setLoader(false))
      setResult(res)
    }
  )

  useEffect(() => {
    inspectionHistory.mutateAsync(vessel?.id)
  }, [apiData]);

  const handleChapter =(item)=>{ 
    dispatch(setInspection(item.id))
    dispatch(setIsUserWise(false))
    dispatch(setUser({}))
    navigate(RouteConstant.CHAPTERPROGRESS)
  }

  const handleOverview =(item)=>{ 
    dispatch(setInspection(item.id))
    navigate(RouteConstant.INSPECTIONOVERVIEW)
  }

  const inspectionExport = inspectionQueries.useVesselinspectionExport(
    async(res)=>{
        dispatch(setInspectionData(res?.data))
        navigate(RouteConstant.INSPECTIONREPORT)
    }
  )


  const handleNavigate = async(id)=>{
    await inspectionExport.mutateAsync({vessel:vessel.id, inspection:id})
  }

  const createMaster = inspectionQueries.useInspectionMutationCreate(
    (res)=>{
        if(res?.message === "Error"){
            {res?.error?.map((error, index) => (
            Object?.values(error).map((errorMessage, innerIndex) => (
                toast.error(errorMessage)
                ))
            ))}
            return
        }
        if(res?.message !== "Success"){
            toast.error("Something went wrong")
            return
        }
        if(res?.message=== "Success"){
            setApiDataAndNotify(res)
            toast.success("Successfully created")
        }
    }
  )

  if(loader){
    return <Loader />
  }

  return (
    <Fragment>

      <BreadCrumbs data={breadcrumb} />

      <div className='card-shadow my-8 max-[768px]:m-2 bg-white rounded-[10px]'>

          <div className='flex justify-between max-[995px]:flex-col items-center p-3 gap-y-3'>
              <div></div>
              <div className='flex max-[561px]:flex-col max-[561px]:w-full justify-end items-center gap-3'>
                  <DrawerComponent vessel_id={vessel.id} entityForm={createMaster} inputFields={inputFields} btnName={'Add Inspection'} header={'Add Inspection'} />
              </div>
          </div>

        {
            inspectionHistory?.isLoading ?
            <div className="w-full flex items-center justify-center py-40">
                <LoaderSmall />
            </div>:
            <Fragment>
              {
                result?.data?.length > 0 ?
                <ThemeProvider theme={theme}>
                    <TableContainer 
                    component={Paper}
                    sx={{
                        boxShadow: 'none',
                        width: '100%',
                        borderRadius: 0,
                        borderTop: '1px solid #e6e6e8',
                        borderBottom: '1px solid #e6e6e8',
                        fontWeight: 500, fontSize: "13px"
                    }}
                    >
                        <Table
                        aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' width={30}>
                                        <b>#</b>
                                    </TableCell>
                                    <TableCell><b>Due Date</b></TableCell>
                                    <TableCell><b>Inspection Progress</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result?.data?.map((row, index) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        fontFamily: 'Montserrat, sans-serif'
                                    }}
                                    >
                                        <TableCell align='center' width={30}>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                              {moment(row?.due_date).format("DD-MM-YYYY")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                              {progressFind(row?.successPercentage)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                          <div className="flex gap-4 justify-end items-center">
                                            <Button onClick={()=>handleChapter(row)} className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary">
                                              <Eye size="16" className='text-white' />
                                              View
                                            </Button>
                                            {
                                              (user.role.role === "CAPT" || user.role.role === "MM") &&
                                              <Button onClick={()=>handleOverview(row)} className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary">
                                                <IconUser size="16" className='text-white' />
                                                Users Details
                                              </Button>
                                            }
                                            <Button onClick={()=>{handleNavigate(row?.id)}} className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary">
                                              <File size="16" className='text-white' />
                                              Non conformance report
                                            </Button>
                                            <ReportModal vessel={vessel?.id} id={row?.id} />
                                          </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>:
                <Empty />
              }
            </Fragment>
        }


      </div>

    </Fragment>
  )
}

export default InspectionHistory

const progressFind =(successPercentage)=>{
  if(isNaN(successPercentage) || successPercentage === undefined || successPercentage === null){
    return <ProgressBar data={0} />
  }else{
    return <ProgressBar data={successPercentage} />
  }
}