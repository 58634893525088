import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const inspectionServies = {
    inspectionHistoryList: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/inspection/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    inspectionExport: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/inspection/inspectionreport/${data.vessel}/${data.inspection}`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    inspectionCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/inspection`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    inspectionOverview: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/inspection/overview/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    inspectionFullReport: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/inspection/full/report`,
            data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
};

export default inspectionServies;