import { useMutation } from "react-query";
import inspectionServie from "../services/inspectionService";

const useInspectionHistoryMutationList = (onData) => {
  return useMutation(inspectionServie.inspectionHistoryList, {
    onSuccess: (data) => onData(data),
  });
};

const useVesselinspectionExport = (onData) => {
  return useMutation(inspectionServie.inspectionExport, {
    onSuccess: (data) => onData(data),
  })
}

const useInspectionMutationCreate = (onData) => {
  return useMutation(inspectionServie.inspectionCreate, {
    onSuccess: (data) => onData(data),
  })
}

const useInspectionOverview = (onData) => {
  return useMutation(inspectionServie.inspectionOverview, {
    onSuccess: (data) => onData(data),
  });
};


const useInspectionFullReport = (onData) => {
  return useMutation(inspectionServie.inspectionFullReport, {
    onSuccess: (data) => onData(data),
  });
};


const inspectionQueries = {
  useInspectionHistoryMutationList,
  useVesselinspectionExport,
  useInspectionMutationCreate,
  useInspectionOverview,
  useInspectionFullReport
};

export default inspectionQueries;
